<template>
  <div data-app>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-btn v-if="permissionCan('menu')" @click="handleRefresh()">{{
                $t("FORM_INPUT_NAMES.refresh")
              }}
            </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export const PERMISSION_TO = "system.setting.";

export default {
  name: "RefreshCache",

  data() {
    return {
      cardTitle: "",
      permissions: [],
      loader: false,
    };
  },

  methods: {
    handleRefresh() {
      let data = {
        command: "AllContentCacheRefresh",
      };

      this.loader = true;
      ApiService.post("handlePost", data)
        .then((response) => {
          console.llog(response.data);
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == PERMISSION_TO + action);
    },
  },

  mounted() {
    if (localStorage.permissions) {
      this.permissions = JSON.parse(localStorage.permissions).filter(
        (item) => item.name.indexOf("system.setting") > -1
      );
    }
  },
};
</script>
